import React, { useEffect } from "react";
import {
  MenuItem,
} from "@material-ui/core";
import Link from "@mui/material/Link";
import {
  SUGGESTION_ACCEPT,
  SUGGESTION_DECLINE,
} from "../../../apiServices/mutations";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import {
  getLongDateWithYear,
  format24Time,
  format24UTCTime,
} from "../../../apiServices/CommonMethods";
import { ActivityStatus } from "../../../apiServices/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material";
import Theme_WIP from "../../../styles/themes/main/Theme_WIP";
import Text from "../../../visualComponents/Text";

// TODO matchtime styling restore
export default function ActivitySuggestionCard({source, activity, onRefresh, AllActivity, color}) {
  const history = useHistory();
  const intl = useIntl();
  const acceptedSuggestion = (activity?.activity?.meetings?.findIndex((m) => m.status === "ACCEPT") ?? -1) + 1;

  const [acceptSuggestion, { data: dataAccept, loading: loadingAccept }] =
    useMutation(SUGGESTION_ACCEPT);
  const [declineSuggestion, { data: dataDecline, loading: loadingDecline }] =
    useMutation(SUGGESTION_DECLINE);

  useEffect(() => {
    if (dataAccept || dataDecline ) {
      onRefresh();
    }
  }, [dataAccept, dataDecline]);

  const acceptSuggestions = (meeting) => {
    const variables = {
      activityId: activity.activity.id,
      meetingID: meeting.id,
    };
    acceptSuggestion({
      variables: variables,
    });
  };
  const declineSuggestions = () => {
    const variables = {
      activityId: activity.activity.id,
    };
    declineSuggestion({
      variables: variables,
    });
  };

  const navigateToMessage = () => {
    history.push("/sendMessage", {
      activity: activity,
    });
  };

  const menuItemForNewActivity = () => {
    return (
      <>
        {activity.activity &&
          activity.activity.meetings.map((meeting, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                acceptSuggestions(meeting);
              }}
            >
              {intl.formatMessage({ id: "AcceptMeeting" }) + " " + (index + 1)}
            </MenuItem>
          ))}
        <MenuItem onClick={declineSuggestions}>
          <FormattedMessage id={"DeclineAll"} />
        </MenuItem>
        {/* <MenuItem onClick={declineSuggestionAndCreateNew}>
          {" "}
          <FormattedMessage id={"DeclineAndSuggestNew_1_On_1"} />
        </MenuItem> */}
        <MenuItem onClick={navigateToMessage}>
          <FormattedMessage id={"Message"} />
        </MenuItem>
        <MenuItem onClick={AllActivity}>
          <FormattedMessage id={"ToAllActivities"} />
        </MenuItem>
      </>
    );
  };

  const menuItemsForAccept = () => {
    return (
      <>
        <MenuItem onClick={declineSuggestions}>
          <FormattedMessage id={"CancelMeeting"} />
        </MenuItem>
        {/* <MenuItem onClick={declineSuggestionAndCreateNew}>
          <FormattedMessage id={"CancelMeetingAndSuggestNew_1_On_1"} />
        </MenuItem> */}
        <MenuItem onClick={navigateToMessage}>
          <FormattedMessage id={"Message"} />
        </MenuItem>
        <MenuItem onClick={AllActivity}>
          <FormattedMessage id={"ToAllActivities"} />
        </MenuItem>
      </>
    );
  };

  const getSuggestionStatus = () => {
    if (
      activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneAccepted
    ) {
      return intl.formatMessage({ id: "AcceptedOption" });
    } else if (
      activity.activity.statusBeforeClosing === ActivityStatus.oneOnOneDeclined
    ) {
      // return "Declined all options";
      return intl.formatMessage({ id: "DeclinedAllOptions" });
    } else if (
      activity.activity.statusBeforeClosing ===
      ActivityStatus.oneOnOneDeclinedAndInitiatedNew
    ) {
      // return "Declined all options and suggested new";
      return intl.formatMessage({ id: "DeclinedAllOptionsAndSuggestedNew" });
    }
  };

  if(source == "menu")
    return activity?.activity?.status === ActivityStatus.oneOnOneAccepted ? menuItemsForAccept() : 
           activity?.activity?.status === ActivityStatus.new              ? menuItemForNewActivity() : null;
  if(source == "canCollapse")
    return undefined;
  return (
    <ThemeProvider theme={Theme_WIP}>
      {activity?.activity?.messages &&
        <Text item xs={12} color={color??"background"}>{activity.activity.messages}</Text>
      }
      {(activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneAccepted ||
        activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneDeclined ||
        activity?.activity?.statusBeforeClosing === ActivityStatus.oneOnOneDeclinedAndInitiatedNew) ?
          <Text item xs={12} color={color??"background"} bold>{getSuggestionStatus()}</Text>
        :
        <Text item xs={12} color={color??"background"} id="MeetingSuggestion" bold/>
      }
      {activity?.activity &&
        <Text item xs={12} color={color??"background"}>
          {activity.activity.meetings.map((meeting, index) => (
            (!acceptedSuggestion || acceptedSuggestion == index + 1) &&
            <>
              {"• " + intl.formatMessage({ id: "Option" }) +
                " " +
                (index + 1) +
                ": " +
                getLongDateWithYear(meeting.meetingDateTime) +
                ", " +
                format24UTCTime(new Date(meeting.meetingDateTime)) +
                ", "}
              <br/>
              {meeting?.locationURL !== null && (
                  meeting?.meetingType === "OFFLINE" ? (
                    meeting.locationURL
                  ) : (
                    <Link
                      color="inherit"
                      href={
                        meeting.locationURL.startsWith("http")
                          ? meeting.locationURL
                          : "https://" + meeting.locationURL
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {meeting.locationURL}
                    </Link>
                  )
              )}
              <br/>
            </>
          ))}
        </Text>
      }
    </ThemeProvider>
  );
}
