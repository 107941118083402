import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ButtonDarkBlue } from "../Buttons/ButtonDarkBlue";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import Link from "@material-ui/core/Link";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CANCEL_CIRCLE,
  UPDATE_CIRCLE,
  UPDATE_SUB_CIRCLE,
} from "../../apiServices/mutations";
import {
  Alert,
  Avatar,
  ClickAwayListener,
  FormHelperText,
  Popper,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { styled } from "@material-ui/styles";
import {
  ClearCache,
  removeItemOnce,
  toBase64,
} from "../../apiServices/CommonMethods";
import LocationAutoComplete from "../Autocomplete/LocationAutoComplete";
import {
  masterDataCircleOperatesIn,
  masterDataCircleTags,
} from "../../apiServices/Constants";
import { SketchPicker } from "react-color";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedBrandColor,
  updateBrandColor,
} from "../../apiServices/Redux/Reducer";
import { CANCEL_CIRCLE_URL, UPGRADE_TO_MASTER_CIRCLE } from "../../Config";
import SuccessAlertWithAction from "../Alert/SuccessAlertWithAction";
import styles from "../../styles/component/Circles/CircleSettingsStyle";
import { FormattedMessage, useIntl } from "react-intl";
import { userRoles } from "../../apiServices/Constants";
import Text from "../../visualComponents/Text";
import Theme_WIP from "../../styles/themes/main/Theme_WIP";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";

const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(styles);

export default function CircleSettings(props) {
  const globals  = useGlobalContext();
  const userRole = globals.state.loggedInUser.role.role;
  const userId   = globals.state.loggedInUser.userId;
  const brandColor = useSelector(selectedBrandColor);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const [checked, setChecked] = React.useState([false, false]);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [anchorElBrand, setAnchorElBrand] = React.useState(null);
  const [anchorElSecondary, setAnchorElSecondary] = React.useState(null);
  const [circleData, setCircleData] = React.useState(props?.circleDetails);
  const [fileSelected, setFileSelected] = React.useState("");
  const [isShowPublishAlert, setIsShowPublishAlert] = React.useState(false);
  const [isUpdated, setIsUpdated] = React.useState(false);
  const [publishStatusBeforeUpdate, setPublishStatusBeforeUpdate] =
    React.useState(0);
  const [SubmitCircle, { loading, data }] = useMutation(UPDATE_CIRCLE, {
    onCompleted: (datas) => {
      ClearCache("circle");
      ClearCache("allCircles");
      // console.log("dataCircle", datas)
      if (typeof datas !== "undefined" && datas.saveCircle.errorCode === 0 && !isUpdated) {
        setIsUpdated(true);
        updateSavedCircleData(datas);
      }
    },
  });
  const [SubmitSubCircle, { loading: loadingSubCircle, data: dataSubCircle }] =
    useMutation(UPDATE_SUB_CIRCLE, {
      onCompleted: (datas) => {
        ClearCache("circle");
        ClearCache("allCircles");
        // console.log("dataSubCircle", datas)
        if (typeof datas !== "undefined" && datas.saveSubCircle.errorCode === 0 && !isUpdated) {
          setIsUpdated(true);
          updateSavedSubCircleData(datas);
        }
      },
    });

  const [
    cancelCircle,
    { data: dataCancelCircle, loading: loadingCancelCircle },
  ] = useMutation(CANCEL_CIRCLE, {
    onCompleted: (datas) => {
      ClearCache("circle");
      ClearCache("allCircles");
      // console.log("dataCancelCircle", dataCancelCircle)
      if (typeof datas !== "undefined" && datas.circleCloseInitiate.errorCode === 0) {
        window.open(CANCEL_CIRCLE_URL, "_blank");
      }
    },
  });

  let [input, setInput] = React.useState({
    name: "",
    circleTypeId: "",
    logo: "",
    location: "",
    operatesIn: "",
    circleTags: "",
    shortDescription: "",
    aboutus: "",
    brandColor: "",
    brandSecondColor: "",
    externalUrl: "",
    label: "",
    membersArePubliclyVisible: false,
    canHaveEvents: false,
    hasNewsUpdates: false,
    hasKnowledgePosts: false,
    canHaveSubCircles: false,
    isHideForPublic: 0,
    id: "",
    isPublished: null,
    locationPlaceId: "",
  });
  let [inputError, setInputError] = React.useState({
    circleTypeId: "",
    logo: "",
    name: "",
    shortDescription: "",
    operatesIn: "",
    aboutus: "",
  });
  const getOperatesInKey = (operatesIn) => {
    const selectedOperatesIn = operatesIn;
    const array = masterDataCircleOperatesIn().filter((item) => {
      return item.value === parseInt(selectedOperatesIn);
    });
    return array.length ? array[0].key : "";
  };
  React.useEffect(() => {
    // if (
    //   typeof data !== "undefined" &&
    //   data.saveCircle.errorCode === 0 &&
    //   !isUpdated
    // ) {
    //   setIsUpdated(true);
    //   updateSavedCircleData();
    // } else if (
    //   typeof dataSubCircle !== "undefined" &&
    //   dataSubCircle.saveSubCircle.errorCode === 0 &&
    //   !isUpdated
    // ) {
    //   setIsUpdated(true);
    //   updateSavedSubCircleData();
    // } else if (
    //   typeof dataCancelCircle !== "undefined" &&
    //   dataCancelCircle.circleCloseInitiate.errorCode === 0
    // ) {
    //   window.open(CANCEL_CIRCLE_URL, "_blank");
    //   // dispatch(updateBrandColor(theme.palette.primary.main));
    //   // history.push("/dashboard/circles");
    // } else 
    if (typeof props.circleDetails !== "undefined") {
      setIsUpdated(false);
      setInitialData(props.circleDetails);
      setCircleData(props.circleDetails);
    }

  }, [props]);

  const updateSavedCircleData = (datas) => {
    // console.log("updateSavedCircleData", datas);

    setInitialData(datas.saveCircle.result);
    const loggedInUserRole = circleData.loggedInUserRole;
    let circleUpdateData = datas.saveCircle.result;
    circleUpdateData["loggedInUserRole"] = loggedInUserRole;
    setCircleData(circleUpdateData);
    dispatch(
      updateBrandColor(
        datas.saveCircle.result.brandColor ?? theme.palette.primary.main
      )
    );
    if (
      datas.saveCircle.result.isPublished === 1 &&
      publishStatusBeforeUpdate === 0
    ) {
      setIsShowPublishAlert(true);
    } else if (datas.saveCircle.result.isPublished === 1) {
      // props.onRefresh(data.saveCircle.result, 0);
      // history.push("/dashboard/circleDetails", {
      //   circleDetails: datas.saveCircle.result,
      //   selectedTab: 0,
      // });
      history.push("/dashboard/circleDetails/?id=" + datas.saveCircle.result.id, {
        circleDetails: datas.saveCircle.result,
        selectedTab: 0,
      });
    } else {
      // history.push("/dashboard/circleDetails", {
      //   circleDetails: datas.saveCircle.result,
      //   selectedTab: 7,
      // });
      history.push("/dashboard/circleDetails/?id=" + datas.saveCircle.result.id, {
        circleDetails: datas.saveCircle.result,
        selectedTab: 7,
      });
      // props.onRefresh(data.saveCircle.result);
    }
  };
  const updateSavedSubCircleData = (datas) => {
    // console.log("updateSavedSubCircleData", datas.saveSubCircle.result);
    setInitialData(datas.saveSubCircle.result);
    const loggedInUserRole = circleData.loggedInUserRole;
    let circleUpdateData = datas.saveSubCircle.result;
    circleUpdateData["loggedInUserRole"] = loggedInUserRole;
    setCircleData(circleUpdateData);
    dispatch(
      updateBrandColor(
        datas.saveSubCircle.result.brandColor ??
        theme.palette.primary.main
      )
    );
    if (
      datas.saveSubCircle.result.isPublished === 1 &&
      publishStatusBeforeUpdate === 0
    ) {
      setIsShowPublishAlert(true);
    } else if (datas.saveSubCircle.result.isPublished === 1) {
      history.push("/dashboard/circleDetails/?id=" + datas.saveSubCircle.result.id, {
        circleDetails: datas.saveSubCircle.result,
        selectedTab: 0,
      });

    } else {
      history.push("/dashboard/circleDetails/?id=" + datas.saveSubCircle.result.id, {
        circleDetails: datas.saveSubCircle.result,
        // selectedTab: 0,
      });
    }
  };

  const setInitialData = (data) => {
    setChecked([
      data.circleTypeId === "1" ? true : false,
      data.circleTypeId === "2" ? true : false,
    ]);
    // console.log("Circle data 456", data)
    if(!isUpdated)
      setInput((prevState) => ({
        ...prevState,
        isCompany: data.isCompany ?? false,
        logo: data.logo,
        label: data.label,
        name: data.name ?? "",
        circleTypeId: data.circleTypeId,
        location: data.location,
        operatesIn: getOperatesInKey(data.operatesIn),
        circleTags: data.circleTags,
        shortDescription: data.shortDescription ?? "",
        aboutus: data.aboutus ?? "",
        brandColor: data.brandColor,
        brandSecondColor: data.brandSecondColor,
        externalUrl: data.externalUrl ?? "",
        membersArePubliclyVisible:
          typeof data.membersArePubliclyVisible === "boolean"
            ? data.membersArePubliclyVisible
            : false,
        canHaveEvents:
          typeof data.canHaveEvents === "boolean" ? data.canHaveEvents : false,
        hasNewsUpdates:
          typeof data.hasNewsUpdates === "boolean" ? data.hasNewsUpdates : false,
        hasKnowledgePosts:
          typeof data.hasKnowledgePosts === "boolean"
            ? data.hasKnowledgePosts
            : false,
        canHaveSubCircles:
          typeof data.canHaveSubCircles === "boolean"
            ? data.canHaveSubCircles
            : false,
        isHideForPublic: data.isHideForPublic ?? 0,
        id: data.id,
        isPublished: data.isPublished ?? 0,
        locationPlaceId: data.locationPlaceId,
      }));
    else
      props.onRefresh(data, 7);
  };
  const handleChange2 = (event) => {
    setChecked([event.target.checked, event.target.checked ? false : true]);
  };

  const handleChange3 = (event) => {
    setChecked([event.target.checked ? false : true, event.target.checked]);
  };

  const onImageSelection = async (event) => {
    let fileSelect = event.target.files[0];
    if (typeof fileSelect !== "undefined") {
      let imageUrl = await toBase64(fileSelect);
      // console.log("imageUrl", imageUrl);
      setFileSelected(imageUrl);
      setInput((prevState) => ({
        ...prevState,
        logo: URL.createObjectURL(event.target.files[0]),
      }));
      setInputError((prevState) => ({
        ...prevState,
        logo: "",
      }));
    } else {
      setFileSelected(null);
      setInput((prevState) => ({
        ...prevState,
        logo: null,
      }));
    }
  };
  const handleChangeTextField = (event) => {
    const { id, value } = event.target;
    setInput((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setInputError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };
  const deleteImage = () => {
    setFileSelected(null)
    setInput((prevState) => ({
      ...prevState,
      logo: null,
    }));
  };

  const onLocationChange = (location) => {
    // console.log("onLocationChange", location);
    if (location) {
      setInput((prevState) => ({
        ...prevState,
        location:
          typeof location === "string" || location instanceof String
            ? location
            : location.description,
      }));
      setInput((prevState) => ({
        ...prevState,
        locationPlaceId:
          typeof location === "string" || location instanceof String
            ? input["locationPlaceId"]
            : location.place_id,
      }));
      setInputError((prevState) => ({
        ...prevState,
        location: "",
      }));
    }
  };
  const isCheckedOperatesIn = (option) => {
    // console.log("option 123", input["operatesIn"])
    const selectedOperatesIn = input["operatesIn"] ?? "";
    if (selectedOperatesIn === option) {
      return true;
    }
    return false;
  };
  const onCircleOperatesInSelection = (event) => {
    var newOperatesIn = "";
    if (event.target.checked) {
      newOperatesIn = event.target.name;
    } else {
      newOperatesIn = "";
    }
    // console.log("newOperatesIn", newOperatesIn);
    setInput((prevState) => ({
      ...prevState,
      operatesIn: newOperatesIn,
    }));
    setInputError((prevState) => ({
      ...prevState,
      operatesIn: "",
    }));
  };
  const isCheckedTag = (option) => {
    const arrayCircleTags = input["circleTags"] ?? [];
    if (arrayCircleTags.includes(option)) {
      return true;
    }
    return false;
  };
  const onCircleTagSelection = (event) => {
    var newTags = input["circleTags"] ?? [];
    if (event.target.checked) {
      newTags.push(event.target.name);
    } else {
      removeItemOnce(newTags, event.target.name);
    }
    setInput((prevState) => ({
      ...prevState,
      circleTags: newTags,
    }));
  };

  const handleChangeSwitch = (event) => {
    if (circleData.circleTypeId === "1") {
      window.open(UPGRADE_TO_MASTER_CIRCLE, "_blank");
    } else {
      if (event.target.name === "isHideForPublic") {
        setInput((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.checked ? 1 : 0,
        }));
      } else {
        setInput((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.checked,
        }));
      }
    }
  };
  const handleChangeSwitchMasterItems = (event) => {
    setInput((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };
  const getOperatesIn = () => {
    const selectedOperatesIn = input["operatesIn"];
    const array = masterDataCircleOperatesIn().filter((item) => {
      return item.key === selectedOperatesIn;
    });
    return array.length ? array[0].value.toString() : "";
  };
  const getCircleTags = () => {
    const arraySelectedTags = input["circleTags"];
    const array = masterDataCircleTags()
      .filter((item) => {
        return arraySelectedTags.includes(item.key);
      })
      .map((item) => {
        return item.value.toString();
      });
    return array.length ? array : null;
  };

  const handleChangeBrandColor = (color) => {
    // console.log("Color selected ", color.hex);
    setInput((prevState) => ({
      ...prevState,
      brandColor: color.hex,
    }));
  };
  const handleChangeBrandSecondaryColor = (color) => {
    // console.log("Color selected ", color.hex);
    setInput((prevState) => ({
      ...prevState,
      brandSecondColor: color.hex,
    }));
  };
  const showBrandColor = (event) => {
    if (circleData.circleTypeId === "1") {
      window.open(UPGRADE_TO_MASTER_CIRCLE, "_blank");
    } else {
      setAnchorElSecondary(null);
      setAnchorElBrand(anchorElBrand ? null : event.currentTarget);
    }
  };
  const showSecondaryColor = (event) => {
    if (circleData.circleTypeId === "1") {
      window.open(UPGRADE_TO_MASTER_CIRCLE, "_blank");
    } else {
      setAnchorElBrand(null);
      setAnchorElSecondary(anchorElSecondary ? null : event.currentTarget);
    }
  };
  const handleClickAway = () => {
    setAnchorElBrand(null);
    setAnchorElSecondary(null);
  };
  const getVariable = () => {
    return {
      logo: fileSelected ? fileSelected : input["logo"],
      name: input["name"],
      circleTypeId: checked[0] ? "1" : "2",
      location: input["location"],
      operatesIn: getOperatesIn(),
      circleTags: input["circleTags"] ? getCircleTags() : null,
      shortDescription: input["shortDescription"],
      aboutus: input["aboutus"],
      brandColor: input["brandColor"],
      brandSecondColor: input["brandSecondColor"],
      externalUrl: input["externalUrl"],
      membersArePubliclyVisible: input["membersArePubliclyVisible"],
      canHaveEvents: input["canHaveEvents"],
      hasNewsUpdates: input["hasNewsUpdates"],
      hasKnowledgePosts: input["hasKnowledgePosts"],
      canHaveSubCircles: input["canHaveSubCircles"],
      isHideForPublic: input["isHideForPublic"],
      id: input["id"],
      label: input["label"],
      locationPlaceId: input["locationPlaceId"],
      isCompany: input["isCompany"]
    };
  };

  const handleSave = async () => {
    // console.log("handleSave", circleData);
    let valid = await checkForErrors();
    if (valid) {
      ClearCache("circle");
      ClearCache("allCircles");
      ClearCache("allEvents");
      setPublishStatusBeforeUpdate(0);
      setIsUpdated(false);
      if (circleData.isSubCircle) {
        let variable = {
          ...getVariable(),
          isPublished: 0,
          parentCircleId: circleData.parentCircleId,
        };
        // console.log("variable subcircle", variable);
        SubmitSubCircle({
          variables: variable,
        });
      } else {
        let variable = {
          ...getVariable(),
          isPublished: 0,
        };
        // console.log("variable circle", variable);
        SubmitCircle({
          variables: variable,
        });
      }
    }
  };
  const handlePublish = async () => {
    let valid = await checkForErrors();
    if (valid) {
      ClearCache("circle");
      ClearCache("allCircles");
      ClearCache("allEvents");
      setPublishStatusBeforeUpdate(circleData.isPublished);
      setIsUpdated(false);
      if (circleData.isSubCircle) {
        let variable = {
          ...getVariable(),
          isPublished: 1,
          parentCircleId: circleData.parentCircleId,
        };
        SubmitSubCircle({
          variables: variable,
        });
        // console.log("variable subcircle", variable);

      } else {
        let variable = {
          ...getVariable(),
          isPublished: 1,
        };
        SubmitCircle({
          variables: variable,
        });
        // console.log("variable circle", variable);

      }
    }
  };
  const checkForErrors = async () => {
    setInputError((prevState) => ({
      ...prevState,
      circleTypeId: !input["circleTypeId"] ? "circleTypeIdError" : "",
      // logo: !input["logo"] ? "logoError" : "",
      name: !input["name"].trim() ? "nameError" : "",
      // shortDescription: !input["shortDescription"].trim()
      //   ? "shortDescriptionError"
      //   : "",
      operatesIn: !input["operatesIn"] ? "operatesInError" : "",
      aboutus: !input["aboutus"].trim() ? "aboutusError" : "",
    }));
    return (
      input["circleTypeId"] &&
      // input["logo"] &&
      input["name"].trim() &&
      // input["shortDescription"].trim() &&
      input["operatesIn"] &&
      input["aboutus"].trim()
    );
  };

  const cancelCircleAction = async () => {
    let variable = {
      circleId: circleData.id,
      loginUserId: userId,
    };
    // console.log("variable", variable);
    cancelCircle({
      variables: variable,
    });
  };
  const handleSucessAlert = async () => {
    props.onRefresh(typeof data !== "undefined" ? data.saveCircle.result : dataSubCircle.saveSubCircle.result, 6);
    history.push("/dashboard/circleDetails", {
      circleDetails:
        typeof data !== "undefined"
          ? data.saveCircle.result
          : dataSubCircle.saveSubCircle.result,
      selectedTab: 6,
    });
  };

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid container spacing={0}>
          <Grid item sm={12} xs={12}>
            {typeof data !== "undefined" && data.saveCircle.errorCode === -1 ? (
              <Alert severity={"error"}>{data.saveCircle.message}</Alert>
            ) : null}
            {typeof dataSubCircle !== "undefined" &&
              dataSubCircle.saveSubCircle.errorCode === -1 ? (
              <Alert severity={"error"}>
                {dataSubCircle.saveSubCircle.message}
              </Alert>
            ) : null}
            {typeof dataCancelCircle !== "undefined" &&
              dataCancelCircle.circleCloseInitiate.errorCode === -1 ? (
              <Alert severity={"error"}>
                {dataCancelCircle.circleCloseInitiate.message}
              </Alert>
            ) : null}
            {isShowPublishAlert && (
              <SuccessAlertWithAction
                message={
                  <FormattedMessage
                    id={"TheCircleIsPublishedYouCanStartInvitingPeopleNow"}
                  />
                }
                handleClose={handleSucessAlert}
                open={true}
              />
            )}
          </Grid>
          <Grid item container spacing={4}>
            {userRole === userRoles.admin ?
              <Grid item sm={12} xs={12}>
                <Typography
                  component="h3"
                  variant="h2"
                  color={"textPrimary"}
                  className={classes.mt20}
                >
                  <FormattedMessage id={"circleSettings_Basic_MasterCircle"} />
                </Typography>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <FormGroup>
                    {loading || loadingCancelCircle || loadingSubCircle ? (
                      <Skeleton variant="rectangular" height={80} />
                    ) : (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={"Basic"}
                              checked={checked[0]}
                              onChange={handleChange2}
                            />
                          }
                          label={<FormattedMessage id={"circleSettings_Basic"} />}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={"Master"}
                              checked={checked[1]}
                              onChange={handleChange3}
                            />
                          }
                          label={
                            <FormattedMessage id={"circleSettings_Master"} />
                          }
                        />
                      </FormGroup>
                    )}
                  </FormGroup>
                </Typography>
              </Grid> : null}
            {(circleData.loggedInUserRole == "SuperUser" || userRole == userRoles.admin) && <Grid container item xs={12}>
                <ThemeProvider theme={Theme_WIP}>
                  <Text item xs={10} bold my={2}>Is bedrijf?</Text>
                </ThemeProvider>
                {loading || loadingCancelCircle || loadingSubCircle ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <Switch
                    {...label}
                    checked={input["isCompany"]}
                    onChange={handleChangeSwitch}
                    name={"isCompany"}
                  />
                )}
            </Grid>}
            <Grid item xs={12} sm={12} className={classes.mt20}>
              <Typography variant="h1" className={classes.btxt}>
                <FormattedMessage id={"circleSettings_HeaderInformation"} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"circleSettings_Logo"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                {loading || loadingCancelCircle || loadingSubCircle ? (
                  <Skeleton variant="rectangular" height={80} />
                ) : (
                  <FormControl variant="filled" className={classes.Select}>
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onImageSelection}
                      />
                      <Avatar
                        sx={{
                          bgcolor: "grey",
                          width: 150,
                          height: 150,
                          boxShadow: 3,
                        }}
                        alt="Add image"
                        src={
                          input["logo"]
                            ? input["logo"]
                            : "/assets/img/mastermatch_circle_default.png"
                        }
                        variant={"square"}
                      />
                    </label>
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      sx={{ paddingTop: 2, width: 150 }}
                    >
                      <label htmlFor="contained-button-file">
                        <Input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={onImageSelection}
                        />
                        {/* <Button
                          variant="href"
                          component="span"
                          size="small"
                          color="default"
                          className={classes.buttonImage}
                        > */}
                        <Typography
                          component="span"
                          variant="h5"
                          color={"textSecondary"}
                          className={classes.p15}
                        >
                          <FormattedMessage id={"edit"} />
                        </Typography>
                        {/* </Button> */}
                      </label>
                      {/* <Button
                        variant="href"
                        component="span"
                        size="small"
                        color="default"
                        className={classes.buttonImage}
                        onClick={deleteImage}
                      >
                        <Typography
                          component="span"
                          variant="h5"
                          color={"textSecondary"}
                          className={classes.p15}
                        >
                          <FormattedMessage id={"delete"} />
                        </Typography>
                      </Button> */}
                      <label htmlFor="contained-button-file">
                        <Typography
                          component="span"
                          variant="h5"
                          color={"textSecondary"}
                          className={classes.p15}
                          onClick={deleteImage}
                        >
                          <FormattedMessage id={"delete"} />
                        </Typography>
                      </label>
                    </Stack>
                    {/* {inputError["logo"] ? (
                      <FormHelperText>
                        {" "}
                        <Typography variant="h5" component="span" color="error">
                          <FormattedMessage id={"PleaseSelectLogo"} />
                        </Typography>{" "}
                      </FormHelperText>
                    ) : null} */}
                  </FormControl>
                )}
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              ></Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"circleSettings_NameCircle"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      id="name"
                      label=""
                      variant="filled"
                      size="small"
                      onChange={handleChangeTextField}
                      value={input["name"]}
                      inputProps={{ maxLength: 50 }}
                      helperText={
                        inputError["name"] ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={"PleaseEnterTheCircleName"} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"circleSettings_ExtraQuote"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      id="label"
                      label=""
                      variant="filled"
                      size="small"
                      onChange={handleChangeTextField}
                      value={input["label"]}
                      inputProps={{ maxLength: 100 }}
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"circleSettings_Location"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <LocationAutoComplete
                      onChange={onLocationChange}
                      value={input["location"]}
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"circleSettings_Short_Description"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      id="shortDescription"
                      label=""
                      variant="filled"
                      size="small"
                      onChange={handleChangeTextField}
                      value={input["shortDescription"]}
                      inputProps={{ maxLength: 100 }}
                      helperText={
                        inputError["shortDescription"] ? (
                          <Typography
                            variant="h5"
                            component="span"
                            color="error"
                          >
                            <FormattedMessage id={"PleaseEnterDescription"} />
                          </Typography>
                        ) : null
                      }
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.mt20}>
              <Typography variant="h1" className={classes.btxt}>
                <FormattedMessage id={"circleSettings_InfoSection"} />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage
                  id={"circleSettings_TheCircleMainlyOperates"}
                />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                {loading || loadingCancelCircle || loadingSubCircle ? (
                  <Skeleton variant="rectangular" height={100} />
                ) : (
                  <FormGroup>
                    {masterDataCircleOperatesIn().map((option, index) => (
                      <Typography
                        variant="h6"
                        color={"textSecondary"}
                        key={index}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCheckedOperatesIn(option.key)}
                            />
                          }
                          label={option.key}
                          onChange={onCircleOperatesInSelection}
                          name={option.key}
                        />
                      </Typography>
                    ))}
                    {inputError["operatesIn"] ? (
                      <FormHelperText>
                        {" "}
                        <Typography variant="h5" component="span" color="error">
                          <FormattedMessage id={"PleaseSelectValue"} />
                        </Typography>{" "}
                      </FormHelperText>
                    ) : null}
                  </FormGroup>
                )}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage id={"circleSettings_SelectCircleTags"} />
              </Typography>
              {loading || loadingCancelCircle || loadingSubCircle ? (
                <Skeleton variant="rectangular" height={80} />
              ) : (
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <FormattedMessage id={"DropDownBox_Text_Select"} />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormGroup>
                          {masterDataCircleTags().map((option, index) => (
                            <Typography
                              variant="h6"
                              color={"textSecondary"}
                              key={index}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isCheckedTag(option.key)}
                                  />
                                }
                                label={option.key}
                                onChange={onCircleTagSelection}
                                name={option.key}
                              />
                            </Typography>
                          ))}
                        </FormGroup>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"circleSettings_AboutUs"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"circleSettings_Description1"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                {loading || loadingCancelCircle || loadingSubCircle ? (
                  <Skeleton variant="rectangular" height={80} />
                ) : (
                  <FormControl variant="filled" className={classes.Select}>
                    <TextareaAutosize
                      id="aboutus"
                      value={input["aboutus"]}
                      variant="filled"
                      aria-label="minimum height"
                      minRows={5}
                      // placeholder="Type..."
                      className={classes.txtarea}
                      //maxLength={100}
                      onChange={handleChangeTextField}
                    />
                    {inputError["aboutus"] ? (
                      <FormHelperText>
                        {" "}
                        <Typography variant="h5" component="span" color="error">
                          <FormattedMessage id={"PleaseEnterAbout"} />
                        </Typography>{" "}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                component="h3"
                variant="h2"
                color={"textPrimary"}
                className={classes.mt10}
              >
                <FormattedMessage id={"circleSettings_YourWebsite"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textPrimary"}
                className={classes.mt7}
              >
                <FormControl variant="filled" className={classes.Select}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <TextField
                      id="externalUrl"
                      value={input["externalUrl"]}
                      variant="filled"
                      aria-label="minimum height"
                      minRows={5}
                      onChange={handleChangeTextField}
                      placeholder={intl.formatMessage({
                        id: "circlePage_AddURL",
                      })}
                    />
                  )}
                </FormControl>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.mt20}>
              <Typography variant="h1" className={classes.btxt}>
                <FormattedMessage id={"circleSettings_Settings"} />
              </Typography>
            </Grid>
            {!circleData.isSubCircle ? (
              <Grid item sm={10} xs={10}>
                <Typography component="h3" variant="h2" color={"textPrimary"}>
                  <FormattedMessage id={"circleSettings_HideMembersToPublic"} />
                </Typography>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <FormattedMessage id={"circleSettings_Description2"} />
                </Typography>
              </Grid>
            ) : null}
            {!circleData.isSubCircle ? (
              <Grid item xs={2} sm={2}>
                {loading || loadingCancelCircle || loadingSubCircle ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <Typography component="h3" variant="h3" color={"textPrimary"}>
                    <Switch
                      {...label}
                      checked={input["membersArePubliclyVisible"]}
                      onChange={handleChangeSwitchMasterItems}
                      name={"membersArePubliclyVisible"}
                    />
                  </Typography>
                )}
              </Grid>
            ) : null}

            <Grid item sm={10} xs={10}>
              <Typography component="h3" variant="h2" color={"textPrimary"}>
                <FormattedMessage id={"circleSettings_EnableCircleEvents"} />
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                color={"textSecondary"}
                className={classes.mt7}
              >
                <FormattedMessage id={"circleSettings_Description3"} />
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Typography component="h3" variant="h3">
                {loading || loadingCancelCircle || loadingSubCircle ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : (
                  <Switch
                    {...label}
                    checked={input["canHaveEvents"]}
                    onChange={handleChangeSwitchMasterItems}
                    name={"canHaveEvents"}
                  />
                )}
              </Typography>
            </Grid>
            {/* {circleData.circleTypeId === "2" ? ( */}
            <Grid item container spacing={4}>
              <Grid item xs={12} sm={12} className={classes.mt20}>
                <Typography
                  variant="h1"
                  className={classes.btxt}
                >
                  <FormattedMessage
                    id={"circleSettings_MasterCircleSettings"}
                  />
                </Typography>
              </Grid>
              <Grid item sm={10} xs={10}>
                <Typography
                  component="h3"
                  variant="h2"
                  color={"textPrimary"}
                >
                  <FormattedMessage
                    id={circleData.isSubCircle ? "circleSettings_HideSubCircleForPublic" : "circleSettings_HideCircleForPublic"}
                  />
                </Typography>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <FormattedMessage id={circleData.isSubCircle ? "circleSettings_SubDescription4" : "circleSettings_Description4"} />
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textPrimary"}
                >
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <Switch
                      {...label}
                      checked={input["isHideForPublic"]}
                      onChange={handleChangeSwitch}
                      name={"isHideForPublic"}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item sm={10} xs={10}>
                <Typography component="h3" variant="h2" color={"textPrimary"}>
                  <FormattedMessage id={"circleSettings_EnableNewsUpdates"} />
                </Typography>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <FormattedMessage id={"circleSettings_Description5"} />
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography component="h3" variant="h3" color={"textPrimary"}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <Switch
                      {...label}
                      checked={input["hasNewsUpdates"]}
                      onChange={handleChangeSwitch}
                      name={"hasNewsUpdates"}
                    />
                  )}
                </Typography>
              </Grid>
              {!circleData.isSubCircle ? (
                <Grid item sm={10} xs={10}>
                  <Typography
                    component="h3"
                    variant="h2"
                    color={"textPrimary"}
                  >
                    <FormattedMessage
                      id={"circleSettings_EnableSubCircles"}
                    />
                  </Typography>
                  <Typography
                    component="h3"
                    variant="h3"
                    color={"textSecondary"}
                    className={classes.mt7}
                  >
                    <FormattedMessage id={"circleSettings_Description6"} />
                  </Typography>
                </Grid>
              ) : null}
              {!circleData.isSubCircle ? (
                <Grid item xs={2} sm={2}>
                  <Typography
                    component="h3"
                    variant="h2"
                    color={"textPrimary"}
                  >
                    {loading || loadingCancelCircle ? (
                      <Skeleton variant="rectangular" height={40} />
                    ) : (
                      <Switch
                        {...label}
                        checked={input["canHaveSubCircles"]}
                        onChange={handleChangeSwitch}
                        name={"canHaveSubCircles"}
                      />
                    )}
                  </Typography>
                </Grid>
              ) : null}

              {userRole === userRoles.admin && <>
              <Grid item sm={10} xs={10}>
                <Typography component="h3" variant="h2" color={"textPrimary"}>
                  <FormattedMessage id={"circleSettings_EnableCirclePosts"} />
                </Typography>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textSecondary"}
                  className={classes.mt7}
                >
                  <FormattedMessage id={"circleSettings_Description7"} />
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography component="h3" variant="h3" color={"textPrimary"}>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <Switch
                      {...label}
                      checked={input["hasKnowledgePosts"]}
                      onChange={handleChangeSwitch}
                      name={"hasKnowledgePosts"}
                    />
                  )}
                </Typography>
              </Grid>
              </>}

              {/* {!circleData.isSubCircle ? (
                <Grid item sm={12} xs={12}>
                  <Typography
                    component="h3"
                    variant="h2"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"circleSettings_ChangeBranding"} />
                  </Typography>
                </Grid>
              ) : null}
              {!circleData.isSubCircle ? (
                <Grid item sm={3} xs={6}>
                  <Typography
                    component="h5"
                    variant="h5"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"circleSettings_MainColor"} />
                  </Typography>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <Typography
                      component="h5"
                      variant="h5"
                      color={"textSecondary"}
                      className={classes.mt7}
                    >
                      <span className={classes.mainColor}>
                        {input["brandColor"] ?? "#006080"}
                      </span>
                      <span
                        style={{
                          padding: "13px 20px",
                          borderRadius: 6,
                          backgroundColor: input["brandColor"] ?? "#006080",
                          position: "relative",
                          top: 10,
                          marginLeft: 6,
                        }}
                        onClick={showBrandColor}
                      ></span>
                    </Typography>
                  )}
                  <Popper
                    id={"anchorElBrand"}
                    open={Boolean(anchorElBrand)}
                    anchorEl={anchorElBrand}
                  >
                    <Box
                      sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
                    >
                      <SketchPicker
                        color={input["brandColor"] ?? "#006080"}
                        onChangeComplete={handleChangeBrandColor}
                      />
                    </Box>
                  </Popper>
                </Grid>
              ) : null}
              {!circleData.isSubCircle ? (
                <Grid item sm={3} xs={6}>
                  <Typography
                    component="h5"
                    variant="h5"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"circleSettings_SecondColor"} />
                  </Typography>
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : (
                    <Typography
                      component="h5"
                      variant="h5"
                      color={"textSecondary"}
                      className={classes.mt7}
                    >
                      <span className={classes.mainColor}>
                        {input["brandSecondColor"] ?? "#72B6CD"}
                      </span>
                      <span
                        style={{
                          padding: "13px 20px",
                          borderRadius: 6,
                          backgroundColor:
                            input["brandSecondColor"] ?? "#72B6CD",
                          position: "relative",
                          top: 10,
                          marginLeft: 6,
                        }}
                        onClick={showSecondaryColor}
                      ></span>
                    </Typography>
                  )}
                  <Popper
                    id={"anchorElSecondary"}
                    open={Boolean(anchorElSecondary)}
                    anchorEl={anchorElSecondary}
                  >
                    <Box
                      sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
                    >
                      <SketchPicker
                        color={input["brandSecondColor"] ?? "#72B6CD"}
                        onChangeComplete={handleChangeBrandSecondaryColor}
                      />
                    </Box>
                  </Popper>
                </Grid>
              ) : null} */}
            </Grid>
            {/* ) : null} */}
            {!circleData.isSubCircle && circleData.circleTypeId === "1" ? (
              <Grid item xs={12} sm={12} className={classes.mt20}>
                <Typography
                  component="h3"
                  variant="h3"
                  color={"textPrimary"}
                  className={classes.mt20}
                >
                  <Link
                    href={UPGRADE_TO_MASTER_CIRCLE}
                    target={"_blank"}
                    rel="noopener"
                    variant="h3"
                    underline="always"
                    className={classes.btxt}
                  >
                    <FormattedMessage
                      id={"circleSettings_UpgradeToAMasterCircle"}
                    />
                  </Link>
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} className={classes.mt20}>
              <Typography variant="h1" className={classes.btxt}>
                <FormattedMessage id={"circleSettings_SaveAndPublish"} />
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Typography component="h3" variant="h3" color={"textSecondary"}>
                <FormattedMessage id={"circleSettings_Description8"} />
              </Typography>
            </Grid>
            {!input["isPublished"] || input["isPublished"] === 0 ? (
              <Grid item sm={12} xs={12}>
                <Grid item sm={4} xs={12}>
                  <Typography component="h3" variant="h3">
                    {loading || loadingCancelCircle || loadingSubCircle ? (
                      <Skeleton variant="rectangular" height={40} />
                    ) : circleData.brandSecondColor ? (
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disableElevation
                      >
                        <FormattedMessage
                          id={
                            "circleSettings_Button_Text_SaveWithoutPublishing"
                          }
                        />
                      </Button>
                    ) : (
                      <ButtonDarkBlue onClick={handleSave}>
                        <FormattedMessage
                          id={
                            "circleSettings_Button_Text_SaveWithoutPublishing"
                          }
                        />
                      </ButtonDarkBlue>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid item sm={12} xs={12}>
              <Grid item sm={4} xs={12}>
                <Typography component="h3" variant="h3">
                  {loading || loadingCancelCircle || loadingSubCircle ? (
                    <Skeleton variant="rectangular" height={40} />
                  ) : circleData.brandSecondColor ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handlePublish}
                      disableElevation
                    >
                      <FormattedMessage
                        id={"circleSettings_Button_Text_Publish"}
                      />
                    </Button>
                  ) : (
                    <ButtonDarkBlue onClick={handlePublish}>
                      <FormattedMessage
                        id={"circleSettings_Button_Text_Publish"}
                      />
                    </ButtonDarkBlue>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {circleData.loggedInUserRole === "Moderator" ? (
              <Grid item sm={12} xs={12}>
                <Grid item sm={4} xs={12}>
                  <Typography component="h3" variant="h3">
                    {loading || loadingCancelCircle || loadingSubCircle ? (
                      <Skeleton variant="rectangular" height={40} />
                    ) : (
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="white"
                        onClick={cancelCircleAction}
                      >
                        <FormattedMessage
                          id={"circleSettings_Button_Text_CancelCircle"}
                        />
                      </Button>
                    )}
                  </Typography>
                </Grid>{" "}
              </Grid>
            ) : null}
            {(circleData.loggedInUserRole === "SuperUser" || userRole === userRoles.admin)
              // circleData.isCancelled === true 
              ? (
                <Grid item sm={12} xs={12}>
                  <Grid item sm={4} xs={12}>
                    <Typography component="h3" variant="h3">
                      {loading || loadingCancelCircle || loadingSubCircle ? (
                        <Skeleton variant="rectangular" height={40} />
                      ) : circleData.brandSecondColor ? (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            history.push("/cancelCircle", {
                              selectedCircleId: circleData.id,
                            });
                          }}
                          disableElevation
                        >
                          <FormattedMessage
                            id={"circleSettings_Button_Text_CancelForAdmin"}
                          />
                        </Button>
                      ) : (
                        <ButtonDarkBlue
                          onClick={() => {
                            history.push("/cancelCircle", {
                              selectedCircleId: circleData.id,
                            });
                          }}
                        >
                          <FormattedMessage
                            id={"circleSettings_Button_Text_CancelForAdmin"}
                          />
                        </ButtonDarkBlue>
                      )}
                    </Typography>
                  </Grid>{" "}
                </Grid>
              ) : null}
          </Grid>
        </Grid>
      </ClickAwayListener>
    </div>
  );
}
