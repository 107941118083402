import React from "react";
import Button from "@mui/material/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from "react-share";
import QRCode from "qrcode.react";
import { Divider, Grid, ThemeProvider } from "@mui/material";
import Text from "../../visualComponents/Text";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import { FormattedMessage } from "react-intl";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function InviteShareButtons({source, parent, data, name, shareUrl, shareContent, uuid, profile, children, authorized}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const [qrbase64, setQrbase64] = React.useState("");
  React.useEffect(() => {
    if (uuid) {
      const canvas = document.getElementById("qrCodeId");
      if (!canvas)
        return;
      const base64QrImage = canvas.toDataURL("image/png");
      setQrbase64(base64QrImage);
    }
  }, [uuid]);

  function generatePDF() {
    const title = "Wie zijn jouw toekomstige zakelijke partnerships?";
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFont("Century Gothic", "bold");
    doc.setFontSize(36);
    const splitTitle = doc.splitTextToSize(title, 500);
    doc.text(splitTitle, 85, 100, "left");
    doc.setFontSize(20);
    doc.setFont("Century Gothic", "normal");
    doc.text("Scan de QR en ontdek wat anderen zoeken. ", 85, 210, "left");
    doc.text(name, 85, 270, "left");
    doc.addImage(qrbase64, "png", 70, 290, 140, 140);
    doc.addImage(
      "../assets/img/mastermatch_logo_petrol_blue_large.svg",
      "JPEG",
      70,
      680,
      300,
      80
    );
    doc.save(name + ".pdf");
  }

  const highlight =(src) => ({hl: (...chunks) => <a href={src} target="_blank" style={{
    textDecoration: "underline",
    color: ThemeConstants.palette.background.contrastText
  }}>{chunks}</a>});

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container gap={ThemeConstants.custom.spacings.lg}>
        <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.sm} columnGap={ThemeConstants.custom.spacings.md}>
            {!profile && <>
            <Text item xs={12} bold variant="h2"    color="background" id={"InviteTab1."+source} />
            <Text item xs={12}      variant="body1" color="background" id={"InviteTab2."+source} />
            </>}
            <Button
            variant="contained"
            color="white"
            id="demo-positioned-button"
            aria-controls="demo-positioned-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            >
                <FormattedMessage id={"invite_socialmedia"}/>
            </Button>
            <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            >
            <FacebookShareButton
            url={shareUrl}
            quote={shareContent}
            >
                <MenuItem onClick={handleClose}>
                    <FormattedMessage id={"invite_facebook"} />
                </MenuItem>
            </FacebookShareButton>
            <LinkedinShareButton
            url={shareUrl}
            >
                <MenuItem onClick={handleClose}>
                        <FormattedMessage id={"invite_linkedin"} />
                </MenuItem>
            </LinkedinShareButton>
            </Menu>
            <WhatsappShareButton url={shareContent + shareUrl}>
                <Button variant="contained" color="white">
                    <FormattedMessage id={"invite_whatsapp"}/>
                </Button>
            </WhatsappShareButton>
        </Grid>
        
        {authorized && <>
        <Divider sx={{width: "100%", borderColor: ThemeConstants.palette.lightPetrol.main}}/>

        <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.sm} columnGap={ThemeConstants.custom.spacings.md}>
            <Text item xs={12} bold variant="h2"    color="background" id={"InviteTab3."+source} />
            <Text item xs={12}      variant="body1" color="background" id={"InviteTab4."+source} />
        </Grid>

        {source == "event" && parent && <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.sm} columnGap={ThemeConstants.custom.spacings.md}>
            <Text item xs={12} bold variant="h2"    color={ThemeConstants.palette.lightPetrol.main} id="InviteTab5.event" />
            <Text item xs={12}      variant="body1" color="background" id="InviteTab6.event" /> 
            <Button variant="contained" onClick={() => 
                    history.push("/inviteAllCirclesToEvent", {
                      circleDetails: parent,
                      eventDetails: data,
                    })}
            >
                <FormattedMessage id="InviteTabButton1"/>
            </Button>
        </Grid>}

        <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.sm} columnGap={ThemeConstants.custom.spacings.md}>
            <Text item xs={12} bold variant="h2"    color={ThemeConstants.palette.lightPetrol.main} id={"InviteTab7."+source} />
            <Text item xs={12}      variant="body1" color="background" id={"InviteTab8."+source} values={
              source == "event" ?
              highlight("https://mastermatch.freshdesk.com/support/solutions/articles/201000072555-wat-zien-deelnemers-bij-een-event-uitnodiging-")
              :
              highlight("https://mastermatch.freshdesk.com/support/solutions/articles/201000076248-wat-zien-deelnemers-bij-een-cirkel-uitnodiging-")} /> 
        </Grid>

        {children}

        <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.sm} columnGap={ThemeConstants.custom.spacings.md}>
            <Text item xs={12} bold variant="h2"    color={ThemeConstants.palette.lightPetrol.main} id={"InviteTab9."+source} />
            <Text item xs={12}      variant="body1" color="background" id={"InviteTab10."+source} /> 
        </Grid>

        <Grid container item rowGap={ThemeConstants.custom.spacings.sm} height="fit-content" maxWidth="120px">
            <Text item xs={12} bold variant="body1" id="accessLink"/>
            <Button variant="contained" onClick={() => {navigator.clipboard.writeText(shareUrl);}}>
                <FormattedMessage id={"Copy_Link"} />
            </Button>
        </Grid>
        <Grid container item rowGap={ThemeConstants.custom.spacings.sm} maxWidth="120px">
            <Text item xs={12} bold variant="body1" id="scanQrCode"/>
            <QRCode
            id="qrCodeId"
            value={shareUrl}
            size={100}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"H"}
            includeMargin={true}
            />
        </Grid>
        <Grid container item rowGap={ThemeConstants.custom.spacings.sm} height="fit-content" maxWidth="120px">
            <Text item xs={12} bold variant="body1" id="dwnldQrCode"/>
            <Button variant="contained" onClick={generatePDF}>
                <FormattedMessage id="download" />
            </Button>
        </Grid>
        </>}
    </Grid>
    </ThemeProvider>
  );
}