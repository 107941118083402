import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { CardMedia, CircularProgress } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { Hidden, ThemeProvider } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Theme_WIP, { ThemeConstants } from "../../styles/themes/main/Theme_WIP";
import Background from "../../visualComponents/Background";
import Text from "../../visualComponents/Text";
import {ReactComponent as More} from "../../svg/ui/more.svg";
import Label from "../../visualComponents/Label";


export default function CircleHeader({errors, loading, image, title, parentTitle, uuid, bio, button, members, menuItems}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const intl = useIntl();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container item xs={12} rowGap={ThemeConstants.custom.spacings.xs} columnGap={ThemeConstants.custom.spacings.ml} pt={8}>
    {errors}
    <Background container item sm xs={3} border="bottomLeft" color="transparent" zIndex="100 !important" rowGap={ThemeConstants.custom.spacings.md}
    sx={{
        minWidth: "90px",
        maxWidth:"170px !important",
    }}>
        <CardMedia
        style={{
            width: "100%",
            borderRadius: "inherit",
            aspectRatio: 1,
        }}
        component="img"
        image={
            image ??
            "/assets/img/mastermatch_circle_default.png"
        }
        alt="profile"
        />
        {button && <Hidden smUp>{button}</Hidden>}
    </Background>
    <Grid container item xs gap={ThemeConstants.custom.spacings.xs} height="fit-content">
        {parentTitle && <Label><FormattedMessage id="CircleHead" values={{circle: parentTitle}}/></Label> }
        <Text item xs={12} variant="h1" color="background" bold>
        {uuid ? <FormattedMessage id="UuidInvite.Title" values={{name: title}} /> : title}
        </Text>
        {!uuid && <React.Fragment>
        {bio &&<Text item xs={12} variant="h2" color="background"> {bio} </Text>}
        {button && <Hidden smDown>{button}</Hidden>}

        {/* member count */}
        {members && <Text variant="body2" color="background" >
            {members}{members === 1
                ? " " +
                intl.formatMessage({
                    id: "CircleDetails_Member",
                })
                : " " +
                intl.formatMessage({
                    id: "CircleDetails_Members",
                })}
        </Text>}
        </React.Fragment>}
    </Grid>

    {(menuItems?.length ?? 0) > 0 && (
        <>
        <Button
            sx={{height: "fit-content", p: 0}}
            onClick={handleClick}
        >
            {loading ? (
            <CircularProgress />
            ) : (
            <More />
            )}
        </Button>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
            vertical: "top",
            horizontal: "left",
            }}
            transformOrigin={{
            vertical: "top",
            horizontal: "left",
            }}
        >
            {menuItems}
        </Menu>
        </>
    )}
    </Grid>

    </ThemeProvider>
  );
}
