//TODO: AAAAAALL of these "constants" should be keys to translations (formatted message id) instead pf values...
//      this is not localizable 💀!
export const Limits = {
  shortText : 100,
  text      : 500,
  longText  : 1500
};

export const CollaborationTypes = {
  projectPartners: "Project samenwerking",
  strategicPartners: "Partnership",
  businessPartners: "Anders",
  coFounding: "Co-founding",
  investment: "Investering",
  mentoring: "Mentoring",
};
export const CollaborationOfInterestArray = [
  {
    itemType: CollaborationTypes.projectPartners,
    fields: [
      "Industries of interest",
      "Location",
      "Projects I like to work on",
      "What I bring to the project",
      "Average duration in months",
    ],
  },
  {
    itemType: CollaborationTypes.strategicPartners,
    fields: [
      "Industries",
      "Partner skills of interest",
      "Location",
      "Partnerships of interest",
      "What I bring to the partnership",
      "Prefered benefits",
    ],
  },
  {
    itemType: CollaborationTypes.businessPartners,
    fields: [
      "Industries of knowledge",
      "Partner skills of interest",
      "Location",
      "Businesses of interest",
      "Skills of knowledge",
      "My commitment",
      "Preferred company role",
      "Prefered benefits",
    ],
  },
  {
    itemType: CollaborationTypes.coFounding,
    fields: [
      "Industries of interest",
      "Co-founder skills of interest",
      "Location",
      "In my pupils ideally I like to see",
      "Available",
    ],
  },
  {
    itemType: CollaborationTypes.investment,
    fields: [
      "Industries of knowledge",
      "Skills of knowledge",
      "What I bring as a mentor",
      "In my pupils ideally I like to see",
      "Available",
    ],
  },
  {
    itemType: CollaborationTypes.mentoring,
    fields: [
      "Industries of knowledge",
      "Skills of knowledge",
      "What I bring as a mentor",
      "In my pupils ideally I like to see",
      "Available",
    ],
  },
];

export const loginType = {
  idserver: "idserver",
  email: "email",
  google: "google",
  linkedin: "linkedin",
};

// export const RequestCollaborationTypes = {
//   projectPartners: "Project partners",
//   strategicPartners: "Strategic partners",
//   businessPartners: "Business partners",
//   coFounding: "Co-founding",
//   investment: "Investment",
//   mentoring: "Mentoring",
//   experts: "Experts",
//   mastersOfMasters: "Masters of masters",
//   masterMinds: "Mastermind",
//   brainStormingPartners: "Brainstorming partner",
//   coworkingDates: "Coworking dates",
// };

// value: backend enumerator value
// key: should be the localization key, but for now it's the actual translation due to having to make a deadline
export const RequestCollaborationTypes = {
  projectPartners: {key: "Project samenwerking", value: "ProjectPartners"},
  strategicPartners: {key: "Partnership", value: "StrategicPartners"},
  businessPartners: {key: "Businesspartner", value: "BusinessPartners"},
  coFounding: {key: "Co-founder", value: "CoFounding"},
  investment: {key: "Inkoop", value: "Investment"},
  mentoring: {key: "Mentor", value: "Mentoring"},
  experts: {key: "Expert/collega", value: "Experts"},
  mastersOfMasters: {key: "Master deal", value: "MastersOfMasters"},
  masterMinds: {key: "Anders", value: "Mastermind"},
  brainStormingPartners: {key: "Inspiratie", value: "BrainstormingPartner"},
  coworkingDates: {key: "Coworker", value: "CoworkingDates"},
};

export const RequestCollaborationOfInterestArray = [
  {
    itemType: RequestCollaborationTypes.projectPartners.key,
    description: "RequestNew_ProjectPartnersRequest_Description",
  },
  {
    itemType: RequestCollaborationTypes.strategicPartners.key,
    description: "RequestNew_StrategicPartnersRequest_Description",
  },
  {
    itemType: RequestCollaborationTypes.businessPartners.key,
    description: "RequestNew_BusinessPartnersRequest_Description",
  },
  {
    itemType: RequestCollaborationTypes.coFounding.key,
    description: "RequestNew_CoFoundingRequest_Description",
  },
  {
    itemType: RequestCollaborationTypes.mentoring.key,
    description: "RequestNew_MentoringRequest_Description",
  },
  {
    itemType: RequestCollaborationTypes.coworkingDates.key,
    description: "RequestNew_CoWorkingDays_Description",
  },
  {
    itemType: RequestCollaborationTypes.brainStormingPartners.key,
    description: "RequestNew_BrainStormingPartners_Description",
  },
  {
    itemType: RequestCollaborationTypes.investment.key,
    description: "RequestNew_InvestmentRequest_Description",
  },
  {
    itemType: RequestCollaborationTypes.mastersOfMasters.key,
    description: "RequestNew_MastersForMaster_Description",
  },
  {
    itemType: RequestCollaborationTypes.experts.key,
    description: "RequestNew_Experts_Description",
  },
  // {
  //   itemType: RequestCollaborationTypes.masterMinds.key,
  //   description: "RequestNew_MasterMind_Description",
  // },
];

export const TaxonomyList = {
  skills: "skills",
  industries: "industries",
  companyLevel: "companyLevel",
  companyRole: "companyRole",
  commitment: "commitment",
  investmentType: "investmentType",
  investorType: "investorType",
  companyStage: "companyStage",
  expertLevel: "expertLevel",
  entepreneurLevel: "entepreneurLevel",
  businessStage: "businessStage",
  businessPartnerBenefitType: "businessPartnerBenefitType",
  period: "period",
  days: "days",
  circleTags: "circleTag",
  circleOperatesIn: "circleOperatesIn",
  eventTags: "eventTags",
  personalLife: "personalLife",
  professionalLife: "professionalLife",

};

export const masterDataSkills = () => {
  return localStorage.getItem(TaxonomyList.skills)
    ? JSON.parse(localStorage.getItem(TaxonomyList.skills))
    : [];
};
export const masterDataIndustries = () => {
  return localStorage.getItem(TaxonomyList.industries)
    ? JSON.parse(localStorage.getItem(TaxonomyList.industries))
    : [];
};
export const masterDataCompanyLevel = () => {
  return localStorage.getItem(TaxonomyList.companyLevel)
    ? JSON.parse(localStorage.getItem(TaxonomyList.companyLevel))
    : [];
};
export const masterDataCompanyRole = () => {
  return localStorage.getItem(TaxonomyList.companyRole)
    ? JSON.parse(localStorage.getItem(TaxonomyList.companyRole))
    : [];
};
export const masterDataCommitment = () => {
  return localStorage.getItem(TaxonomyList.commitment)
    ? JSON.parse(localStorage.getItem(TaxonomyList.commitment))
    : [];
};
export const masterDataInvestmentType = () => {
  return localStorage.getItem(TaxonomyList.investmentType)
    ? JSON.parse(localStorage.getItem(TaxonomyList.investmentType))
    : [];
};
export const masterDataInvestorType = () => {
  return localStorage.getItem(TaxonomyList.investorType)
    ? JSON.parse(localStorage.getItem(TaxonomyList.investorType))
    : [];
};
export const masterDataCompanyStage = () => {
  return localStorage.getItem(TaxonomyList.companyStage)
    ? JSON.parse(localStorage.getItem(TaxonomyList.companyStage))
    : [];
};
export const masterDataBusinessStage = () => {
  return localStorage.getItem(TaxonomyList.businessStage)
    ? JSON.parse(localStorage.getItem(TaxonomyList.businessStage))
    : [];
};
export const masterDataExpertLevel = () => {
  return localStorage.getItem(TaxonomyList.expertLevel)
    ? JSON.parse(localStorage.getItem(TaxonomyList.expertLevel))
    : [];
};
export const masterDataEnterpreneur = () => {
  return localStorage.getItem(TaxonomyList.entepreneurLevel)
    ? JSON.parse(localStorage.getItem(TaxonomyList.entepreneurLevel))
    : [];
};
export const masterDataPeriod = () => {
  return localStorage.getItem(TaxonomyList.period)
    ? JSON.parse(localStorage.getItem(TaxonomyList.period))
    : [];
};
export const masterDataDays = () => {
  return localStorage.getItem(TaxonomyList.days)
    ? JSON.parse(localStorage.getItem(TaxonomyList.days))
    : [];
};
export const masterDataPreferedBenefit = () => {
  return localStorage.getItem(TaxonomyList.businessPartnerBenefitType)
    ? JSON.parse(localStorage.getItem(TaxonomyList.businessPartnerBenefitType))
    : [];
};
export const masterDataCircleTags = () => {
  return localStorage.getItem(TaxonomyList.circleTags)
    ? JSON.parse(localStorage.getItem(TaxonomyList.circleTags))
    : [];
};
export const masterDataCircleOperatesIn = () => {
  return localStorage.getItem(TaxonomyList.circleOperatesIn)
    ? JSON.parse(localStorage.getItem(TaxonomyList.circleOperatesIn))
    : [];
};
export const masterDataEventTags = () => {
  return localStorage.getItem(TaxonomyList.eventTags)
    ? JSON.parse(localStorage.getItem(TaxonomyList.eventTags))
    : [];
};
export const masterDataPersonalLife = () => {
  return localStorage.getItem(TaxonomyList.personalLife)
    ? JSON.parse(localStorage.getItem(TaxonomyList.personalLife))
    : [];
};
export const masterDataprofessionalLife = () => {
  return localStorage.getItem(TaxonomyList.professionalLife)
    ? JSON.parse(localStorage.getItem(TaxonomyList.professionalLife))
    : [];
};

export const masterDataPreferenceList = () => {
  return [
    { key: "Solo ondernemers", value: "0" },
    { key: "MKB", value: "1" },
    { key: "Startups", value: "2" },
    { key: "Scaleups", value: "3" },
    { key: "Grootzakelijk", value: "4" },
    { key: "Alleen matches en leden van mijn cirkels", value: "5" },
  ];
};
export const ActivityTypes = {
  matchRequest: "Match Request",
  message: "message",
  introduction: "Introduction",
  thankyou: "thankyou",
  respondToRequest: "Response to request",
  oneOnOne: "One on one",
};

export const RequestSelectionOptions = [
  {
    type: "Masters zonder match",
    key: "2",
  },
  {
    type: "Mijn inner circle",
    key: "1",
  },
];

export const ActivityItems = [
  {
    type: "Todos",
    key: 1,
  },
  {
    type: "Pending",
    key: 2,
  },
];

export const MastersTabItems = [
  {
    type: "filter_title_all",
    key: 1,
  },
  {
    type: "filter_title_mymatches",
    key: 2,
  },
];

export const ActivityStatus = {
  new: "NEW",
  inProgress: "In Progress",
  Closed: "CLOSED",
  matchRequestAccepted: "MATCH_REQUEST_ACCEPTED",
  matchRequestDeclined: "MATCH_REQUEST_DECLINED",
  oneOnOneAccepted: "SUGGESTION_ACCEPTED",
  oneOnOneDeclined: "SUGGESTION_DECLINED",
  oneOnOneDeclinedAndInitiatedNew: "DECLINED_ALL_AND_SUGGESTED",
  activityInvitationAccepted: "CIRCLE_INVITATION_ACCEPTED",
  activityInvitationDeclined: "CIRCLE_INVITATION_DECLINED",
  activityJoinAsGuestAccepted: "JOIN_AS_GUEST_ACCEPTED",
  activityJoinAsGuestDeclined: "JOIN_AS_GUEST_DECLINED",
  activityEventInviteAccepted: "EVENT_INVITATION_ACCEPTED",
  activityEventInviteDeclined: "EVENT_INVITATION_DECLINED",
};

export const ActivitySource = {
  matchRequest: "Match Request",
  collabRequest: "Collaboration Request",
  general: "General",
  circleNewsUpdate: "Circle News Update",
  circlePostRespond: "Circle Posts",
  eventNewsUpdate: "Event News Update",
};

export const CircleTypes = [
  {
    type: "Basic",
    key: 1,
  },
  {
    type: "Master",
    key: 2,
  },
];

export const CircleOperatesIn = [
  {
    type: "Online",
    key: "1",
  },
  {
    type: "Offline",
    key: "2",
  },
  {
    type: "Hybrid",
    key: "3",
  },
];

export const CirclesTabItems = [
  {
    type: "All",
    key: 1,
  },
  {
    type: "My circles",
    key: 2,
  },
];
export const CompaniesTabItems = [
  {
    type: "All",
    key: 1,
  },
  {
    type: "My companies",
    key: 2,
  },
];

export const FeedsTabItems = [
  {
    type: "filter_title_all",
    key: 1,
  },
  {
    type: "filter_title_requests",
    key: 2,
  },
  {
    type: "filter_title_circle_post",
    key: 3,
  },
];

export const EventsTabItems = [
  {
    type: "All",
    key: 1,
  },
  {
    type: "My events",
    key: 2,
  },
  {
    type: "Past events",
    key: 3,
  },
];
export const PaymentStatus = [
  {
    type: "paid",
    key: 1,
  },
  {
    type: "in_progress",
    key: 0,
  },
  {
    type: "failed",
    key: -1,
  },
];

export const EventsParticipantScope = [
  {
    key: "Open event",
    description: "OpenEvent_Description",
    value: 1,
    backendKey: "Open",
  },
  {
    key: "Gast event",
    description: "GuestEvent_Description",
    value: 2,
    backendKey: "Members And Guests",
  },
  {
    key: "Besloten event",
    description: "InviteOnlyEvent_Description",
    value: 3,
    backendKey: "Members Only",
  },
];

export const userRoles = {
  admin: "Admin",
  premium: "Premium",
  freemium: "Freemium",
};
