import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { GET_CIRCLE_MEMBERS } from "../../apiServices/Queries";
import { useLocation } from "react-router-dom";
import Filters from "../Filters";
import Styles from "../../styles/component/Cards/CircleMemberCardStyle";
import MemberCard from "../../components/Cards/MemberCard";
import { Alert, CircularProgress, Stack } from "@mui/material";
import { Paper } from "@material-ui/core";
import { Waypoint } from "react-waypoint";
import { FormattedMessage } from "react-intl";
import { useGlobalContext } from "../../apiServices/Providers/AppGlobalStateProvider";
import Accordion from "../../visualComponents/Accordion";

const useStyles = makeStyles(Styles);

export default function CircleMembers(props) {
  const globals  = useGlobalContext();
  const userId   = globals.state.loggedInUser.userId;
  const classes = useStyles();
  const [members, setMembers] = React.useState([]);
  const [circledata, setCircleData] = React.useState(props?.circleDetails);
  const location = useLocation();
  let [input, setInput] = React.useState({
    location: null,
    industries: null,
    skills: null,
    maxDistance: null,
    collaborationType: null,
    businessType: null,
    searchInput: null,
    pageNumber: 1,
  });
  const getVariable = () => {
    const variable = {
      circleId: circledata.id,
      userId: userId,
      pageNumber: input["pageNumber"],
      pageSize: parseInt(50),
      searchInput: input["searchInput"],
      skills: input["skills"],
      industries: input["industries"],
      collaborationType: input["collaborationType"],
      businessTypes: input["businessType"],
      location: input["location"],
      maxDistance: input["maxDistance"],
    };
    // console.log("membervariable", variable);
    return variable;
  };
  const { loading, fetchMore, error, refetch } = useQuery(
    GET_CIRCLE_MEMBERS,
    {
      variables: getVariable(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (typeof data !== "undefined" && data.circleMembers.errorCode === 0) {
          const response = data.circleMembers.result;
          // console.log("circleMembers", response);
          // console.log("pageNumber", input["pageNumber"]);
          if (circledata.loggedInUserRole !== "Moderator") {
            if (input["pageNumber"] === 1) {
              let circleList = response.filter(list => {
                return list.isMember === true;
              });
              setMembers(circleList);
            } else {
              if (data.circleMembers.result.length) {
                let circleList = response.filter(list => {
                  return list.isMember === true;
                });
                setMembers(prevState => [...prevState, ...circleList]);
              }
            }
          } else {
            if (input["pageNumber"] === 1) {
              setMembers(response);
            } else {
              if (response.length) {
                setMembers(prevState => [...prevState, ...response]);
              }
            }
          }
        } else {
          setMembers([]);
        }
      },
    }
  );

  useEffect(() => {
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
    }
    // refetch()
  }, [location, input, props]);

  const filterAction = inputs => {
    setMembers([]);
    setInput((prev) => ({...prev, ...inputs, pageNumber: 1}));
    fetchMore({
      variables: {
        ...inputs,
        pageNumber: 1,
      },
    });
  };

  const refresh = () => {
    setMembers([]);
    setInput(prevState => ({
      ...prevState,
      pageNumber: parseInt(1),
    }));
    refetch();
  };

  return (
    <Grid container gap={4}>
      {(circledata.loggedInUserRole === "Moderator" || circledata.loggedInUserRole === "SuperUser") && 
      <Accordion summary="ManagerInfoSummary" details="ManagerInfoDetails"/>}

      
      {error && <Grid item xs={12}>
        <Alert severity="error">
          {error.length ? (
            error
          ) : (
            <FormattedMessage id={"somethingWentWrong"} />
          )}
        </Alert>
      </Grid>
      }
      <Filters search includeBusiness includeIndustry includeSkills onSearch={filterAction} useRefresh/>

      {loading && !members.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />{" "}
          </Stack>
        </Box>
      ) : members.length > 0 ? (
        members.map((memberdata, index) => (
          <React.Fragment key={index}>
            <MemberCard
              data={memberdata}
              circleDetails={circledata}
              onRefresh={refresh}
              key={index}
            />
            {index === members.length - 1 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  const newPage = input["pageNumber"] + 1;
                  // console.log("newPage", newPage);
                  setInput(prevState => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <Grid item xs={12} sm={12}>
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
