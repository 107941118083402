import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { CircularProgress, Paper } from "@material-ui/core";
import { GET_ALL_SUB_CIRCLES } from "../../apiServices/Queries";
import { GetUserId } from "../../apiServices/CommonMethods";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import CircleCard from "../Cards/CircleCard";
import styles from "../../styles/component/Circles/CircleCirclesStyle";
import { Waypoint } from "react-waypoint";
import { FormattedMessage } from "react-intl";
import { useCachedQuery } from "../../apiServices/Queries/CachedQuery";
import { ThemeConstants } from "../../styles/themes/main/Theme_WIP";

const useStyles = makeStyles(styles);

export default function CircleCirles(props) {
  const classes = useStyles();
  const history = useHistory();
  const userId = GetUserId();

  const [circledata, setCircleData] = React.useState(props?.circleDetails);
  const [circles, setCircles] = React.useState([]);
  let [input, setInput] = React.useState({
    pageNumber: 1,
  });
  useEffect(() => {
    // console.log(props.circleDetails);
    if (typeof props.circleDetails !== "undefined") {
      setCircleData(props.circleDetails);
    }
  }, [props]);

  const getVariable = () => {
    const variable = {
      loggedInUser: userId,
      circleId: circledata.id ?? props.circleDetails?.id,
      pageSize: 25,
      pageNumber: input["pageNumber"],
    };
    // console.log("variable12", variable);
    return variable;
  };

  const { data, loading } = useCachedQuery(
    "circles_subs",
    GET_ALL_SUB_CIRCLES,
    getVariable(),
    (data) => data?.allSubCircles?.result?.circles,
    30,
    true
  );
  useEffect(() => {
    if(!data) return;
    if (input["pageNumber"] === 1)
      setCircles(data);
    else if (data.length > 0)
      setCircles((prevState) => [
        ...prevState,
        ...data,
      ]);
  }, [data]);

  return (
    <Grid container gap={ThemeConstants.custom.spacings.md}>
      {circledata.loggedInUserRole === "Moderator" || circledata.loggedInUserRole === "SuperUser" ? (
        <Grid item xs={3} style={{ float: "right" }}>
          {/* <Hidden mdUp>
            <Icon
              sx={{
                color: circledata.brandSecondColor
                  ? brandColor
                  : theme.palette.secondary.main,
                fontSize: 40,
              }}
              onClick={() => {
                history.push("/AddNewSubCircle", {
                  parentCircleData: circledata,
                });
              }}
            >
              add_circle
            </Icon>
          </Hidden>
          <Hidden smDown> */}
          <Button
            fullWidth
            variant="contained"
            onClick={() =>
              history.push("/AddNewSubCircle", {
                parentCircleData: circledata,
              })
            }
            disableElevation
          >
            <FormattedMessage id={"subCircle_Add_New_Button"} />
          </Button>
          {/* </Hidden> */}
        </Grid>
      ) : null}
      {loading && !circles.length ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            flex: "row",
            justifyContent: "center",
            width: "100%",
            height: 300,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <CircularProgress />
            <Typography
              component="h5"
              variant="h5"
              className={classes.title}
            ></Typography>
          </Stack>
        </Box>
      ) : circles.length > 0 ? (
        circles.map((item, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              onClick={() => {
                // props.UpdateCircleDetails(item);
                history.push("/dashboard/circleDetails/?id=" + item.id, {
                  selectedTab: 0,
                });
              }}
            >
              <CircleCard circleData={item} />
            </Grid>
            {index === circles.length - 1 && (
              <Waypoint
                scrollableAncestor={window}
                onEnter={() => {
                  // console.log("index", index);
                  const newPage = input["pageNumber"] + 1;
                  // console.log("newPage", newPage);
                  setInput((prevState) => ({
                    ...prevState,
                    pageNumber: newPage,
                  }));
                }}
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <Grid item xs={12} sm={12}>
          <Paper elevation={3} className={classes.tabcontent}>
            <Grid container spacing={4}>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                  flex: "row",
                  justifyContent: "center",
                  width: "100%",
                  height: 200,
                }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    color={"textPrimary"}
                  >
                    <FormattedMessage id={"noDataAvailable"} />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
