import * as React from 'react';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import Image from "../Media/Image";
import { useCurrentUserProfile } from '../../apiServices/CommonDataObjects';
import { useActivityByInviteSource, useInviterInfoPost } from '../../apiServices/Queries/Invites/InviteInfo';
import { useGlobalContext } from '../../apiServices/Providers/AppGlobalStateProvider';
import { useGetCircle } from '../../apiServices/Queries/Circles/GetCircle';
import { useGetEvent } from '../../apiServices/Queries/Events/GetEvent';
import { useEffect } from 'react';
import Theme_WIP, { ThemeConstants } from '../../styles/themes/main/Theme_WIP';
import Text from '../../visualComponents/Text';
import { Button, ThemeProvider } from '@mui/material';
import Background from '../../visualComponents/Background';
import { ACCEPT_EVENT_INVITE, CIRCLE_INVITE_ACCEPT } from '../../apiServices/mutations';
import { ClearCache } from '../../apiServices/CommonMethods';
import { useMutation } from '@apollo/client';

export default function MeetCollaborations(props) {
  const globals = useGlobalContext();
  const profile = useCurrentUserProfile();
  const history = useHistory();
  const inviteInfo  = localStorage.getItem("inviteInfo") ? JSON.parse(localStorage.getItem("inviteInfo")) : undefined;
  const activityId  = useActivityByInviteSource(inviteInfo?.eventId ?? inviteInfo?.circleId, globals.state.loggedInUser.userId);
  const circle      = useGetCircle(inviteInfo?.circleId, null);
  const event       = useGetEvent (inviteInfo?.eventId , null);
  const invited     = (event.data || circle.data);
  const [acceptInvitation] =
    useMutation(event?.data? ACCEPT_EVENT_INVITE : CIRCLE_INVITE_ACCEPT, {onCompleted: () => {
      if(event?.data)
        history.push("/dashboard/eventDetails/?id="+ (event?.data?.id), {selectedTab: 3});
      else
        window.location.href = "/dashboard/circleDetails/?id="+ (circle?.data?.id); //temporary fix
    }});
  const acceptInvitationAction = () => {
    localStorage.setItem("onBoardProgress", 4);
    ClearCache("circle");
    ClearCache("allCircles");
    ClearCache("event");
    ClearCache("allEvents");
    acceptInvitation({
      variables: {
        activityId: activityId.data,
        loginUserId: globals.state.loggedInUser.userId,
      },
      onCompleted: () => { 
        if(event?.data)
          history.push("/dashboard/eventDetails/?id="+ (event?.data?.id), {selectedTab: 3});
        else
          window.location.href = "/dashboard/circleDetails/?id="+ (circle?.data?.id);
      }
    });
  };

  useEffect(() => {
    if(inviteInfo?.eventId)
        event.updateVariables({id : inviteInfo?.eventId, loggedInUser: ""});
  },[inviteInfo?.eventId]);

  useEffect(() => {
    if(inviteInfo?.circleId)
        circle.updateVariables({circleId: inviteInfo?.circleId, loggedInUser: ""});
  },[inviteInfo?.circleId]);

  const JoinSection = () => <>
    <Text item xs={12} variant="h2"      color="background" align="center" gridProps={{mb: ThemeConstants.custom.spacings.ml}} id="joinText" values={{entity: event.data?.title ?? circle.data?.name}}/>
    <Text item xs={12} variant="h1" bold color="background" align="center">{event.data?.title ?? circle.data?.name}</Text>
    {activityId && <>
    <Button variant="contained" onClick={() => acceptInvitationAction()}              ><FormattedMessage id={"Accept"} /></Button>
    <Button variant="contained" onClick={() => handleClick('/dashboard/requests')} color="white"><FormattedMessage id={"Skip"} /></Button>
    </>}
  </>;


  function handleClick(href) {
    localStorage.setItem("onBoardProgress", 4);
    history.push(href)
  };

  React.useEffect(() => {
    if (localStorage.getItem("onBoardProgress") != null && localStorage.getItem("onBoardProgress") <= 3) {
      history.push("/OnboardingStepper");
    }
  }, []);

  return (
    <ThemeProvider theme={Theme_WIP}>
    <Grid container maxWidth={"690px"} justifyContent='center' rowGap={ThemeConstants.custom.spacings.lg} columnGap={ThemeConstants.custom.spacings.sm} px={ThemeConstants.custom.spacings.sm}>
      <Grid item width={"100px !important"}>
        <Image
          sx={{
            borderRadius: '20px 20px 20px 0px',
          }}
          width={"100%"}
          src="/assets/img/iamFull.png"/>
      </Grid>

      <Text item sm={12} xs={12} variant="h2" color={invited ? "background" : "primary"} align='center' id={"OnboardingEvents.Pagedescription"} values={{firstname: profile?.givenName}} />
      
      {invited && <JoinSection />}
      {(event.data || circle.data) && <Grid container item xs={12} justifyContent="center">
        {event .data && <img src={event .data.logo ?? "/assets/img/mastermatch_event_default.png"}  style={{borderRadius: ThemeConstants.custom.borderRadius.bottomLeft, maxWidth: "100%" , width: "100%", objectFit: "cover", aspectRatio: "690 / 343"}}/>}
        {circle.data && <img src={circle.data.logo ?? "/assets/img/mastermatch_circle_default.png"} style={{borderRadius: ThemeConstants.custom.borderRadius.bottomLeft, maxWidth: "178px", objectFit: "cover", aspectRatio: "1 / 1"    }}/>}
      </Grid>}

      {!invited &&
      <>
      <Background page color="primary"/>
      <Grid item xs={12} container justifyContent='center'rowGap={ThemeConstants.custom.spacings.md}>
        <Text item xs={12} color="primary" align='center' id={"OnboardingEvents.Pagedescription1"  } variant="h1" bold/>
        <Text item xs={12} color="primary" align='center' id={"OnboardingEvents.Pagedescription1.5"} variant="h2"/>
        <Button color="white" variant="contained" onClick={() => handleClick('/dashboard/requests')}><Text color="white" variant="h2" id={"toRequests"} /></Button>
      </Grid>
      {/* <Grid item xs={12} container justifyContent='center'rowGap={ThemeConstants.custom.spacings.md}>
        <Text item xs={12} color="primary" align='center' id={"OnboardingEvents.Pagedescription3"} variant="h2" bold/>
        <Button color="white" variant="contained" onClick={() => handleClick('/dashboard/profile')}><FormattedMessage id={"toMyProfile"} /></Button>
      </Grid>
      <Grid item xs={12} container justifyContent='center'rowGap={ThemeConstants.custom.spacings.md}>
        <Text item xs={12} color="primary" align='center' id={"OnboardingEvents.Pagedescription2"} variant="h2" bold/>
        <Button color="white" variant="contained" onClick={() => handleClick('/dashboard/activity')}><FormattedMessage id={"toMessages"} /></Button>
      </Grid> */}
      </>
      }

    </Grid>
    </ThemeProvider>
  );
}